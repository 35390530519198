import styled from '@emotion/styled'

import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

export const SWrapper = styled.div`
  padding: 0 ${size(6)};

  @media ${media.lte('mobile')} {
    padding: 0;
  }
`
