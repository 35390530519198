import { Flex } from 'driverama-core/components/Flex'
import { InputSwitch } from 'driverama-core/components/inputSwitch/InputSwitch'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextSubhead } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { State } from 'sections/filters/context/FiltersState'

type Props<K extends keyof State> = {
  value?: boolean
  label: string
  invertedLogic?: boolean
  name: keyof State[K]['filters']
  onChange: (name: keyof State[K]['filters'], value?: boolean) => void
}

export type BoolFilter<K extends keyof State> = Omit<Props<K>, 'onChange'>

const useBoolSwitchOptions = (invertedLogic?: boolean) => {
  const { t } = useTranslation(['core'])

  return [
    {
      label: t('core:yes'),
      value: invertedLogic ? false : true
    },
    {
      label: t('core:no'),
      value: invertedLogic ? true : false
    }
  ]
}

export function BoolSwitch<K extends keyof State>({
  value,
  label,
  invertedLogic,
  onChange,
  name
}: Props<K>) {
  const options = useBoolSwitchOptions(invertedLogic)

  return (
    <Flex variant="column">
      <Spacer size={1} axis="vertical" />
      <TextSubhead>{label.toUpperCase()}</TextSubhead>

      <Spacer size={1} axis="vertical" />

      <InputSwitch
        value={value}
        onChange={newValue => onChange(name, newValue)}
        options={options}
      />
    </Flex>
  )
}
