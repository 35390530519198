import { isAfter, isBefore } from 'date-fns'

export type AvailableDate = { date: Date; times: string[] }

export type DatepickerValue = { date?: Date; time?: string } | undefined

export type DatepickerOnChangeFn = (value: DatepickerValue) => void

export function isAvailable(
  date: Date,
  minDate?: Date,
  maxDate?: Date
): boolean {
  const isAfterMinDate = minDate ? isAfter(date, minDate) : true
  const isBeforeMaxDate = maxDate ? isBefore(date, maxDate) : true

  return isAfterMinDate && isBeforeMaxDate
}

export const getLocale = async (locale?: string) => {
  let short = 'en-US'
  if (locale && locale !== 'en') {
    short = locale
  }

  return await import(`date-fns/locale/${short}/index.js`)
}
