import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InputSearch } from 'driverama-core/components/inputSearch/InputSearch'
import { useModal } from 'driverama-core/components/modal/Modal'
import { media } from 'driverama-core/styles/media'
import { useMediaQuery } from 'driverama-core/styles/responsivity'

import DotsIcon from 'images/icons/IconDots.svg'

import { SerializedStyles } from '@emotion/react'
import { RangeInputFilter } from 'components/rangeInputs/RangeInputs'
import { useFiltersContext } from '../context/FiltersContext'
import { State } from '../context/FiltersState'
import { SelectFilter, setFiltersForModule } from '../FilterHooks'
import { FiltersModal } from './filtersModal/FiltersModal'
import { SFilterButton, SWrapper } from './MobileFilters.styled'

type Props<K extends keyof State> = {
  filterModule: K
  searchKey: string
  isMultiValueSearch?: boolean
  withoutSearch?: boolean
  filterDialogWrapperStyles?: SerializedStyles
  defaultSearch?: string
  menuFilters?: {
    dropdowns?: SelectFilter<K>[]
    rangeInputs?: RangeInputFilter[]
  }
  searchInputPlaceholder?: string
}

export function MobileFilters<K extends keyof State>({
  filterModule,
  searchKey,
  isMultiValueSearch = false,
  withoutSearch,
  filterDialogWrapperStyles,
  defaultSearch,
  menuFilters,
  searchInputPlaceholder
}: Props<K>) {
  const { t } = useTranslation(['core'])

  const { dispatch } = useFiltersContext()
  const [search, setSearch] = useState(defaultSearch)

  const isMobile = useMediaQuery(media.lte('mobile'))

  const handleSearch = (value: string) => {
    setSearch(value)
    const updatedFilter = {
      [`${searchKey}`]: isMultiValueSearch ? [] : value
    }
    dispatch(setFiltersForModule(filterModule, updatedFilter))
  }

  const [filterModal, openFilterModal] = useModal(
    () => <FiltersModal module={filterModule} menuFilters={menuFilters} />,
    {
      closeLabel: t('core:close'),
      wrapperStyles: filterDialogWrapperStyles
    }
  )

  return (
    <>
      <SWrapper>
        {!withoutSearch && (
          <InputSearch
            name={searchKey}
            noOutline={true}
            value={search ?? ''}
            onChange={setSearch}
            label={t('core:header_search_label')}
            placeholder={
              searchInputPlaceholder ?? t('core:header_search_placeholder')
            }
            onSubmit={() => {
              handleSearch(search ?? '')
            }}
            onClear={() => handleSearch('')}
          />
        )}

        <SFilterButton variant="outline" onClick={openFilterModal}>
          {isMobile || withoutSearch ? t('core:table_filters') : <DotsIcon />}
        </SFilterButton>
      </SWrapper>
      {filterModal}
    </>
  )
}
