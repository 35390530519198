export const TESTING_IDS = {
  buyingWizardMobileBack: 'buying-wizard-mobile-back-button',
  buyingWizardContinueTopButton: 'buying-wizard-continue-top-button',
  buyingWizardContinueBottomButton: 'buying-wizard-continue-bottom-button',
  buyingWizardNoMatchButton: 'buying-wizard-no-match-button',
  buyingWizardDesktopCloseButton: 'buying-wizard-desktop-close-button',
  buyingWizardDesktopBackButton: 'buying-wizard-desktop-back-button',
  buyingWizardBranchRebookButton: 'buying-wizard-branch-rebook-button',
  buyingWizardProfileButton: 'buying-wizard-profile-button',
  buyingWizardInterior: 'buying-wizard-interior',
  buyingWizardExterior: 'buying-wizard-exterior',
  buyingWizardMechanical: 'buying-wizard-mechanical',
  buyingWizardAccidentSeverityYesButton:
    'buying-wizard-accident-severity-yes-button',
  buyingWizardAccidentSeverityNoButton:
    'buying-wizard-accident-severity-no-button',
  buyingWizardContinueButton: 'buying-wizard-continue-button',
  buyingWizardSkipContinueButton: 'buying-wizard-skip-continue-button',
  buyingWizardSkipCancelButton: 'buying-wizard-skip-cancel-button',
  buyingWizardSkipButton: 'buying-wizard-skip-button',
  buyingWizardMakesBackButton: 'buying-wizard-makes-back-button',
  buyingWizardMakeButton: 'buying-wizard-make-button',
  buyingWizardServiceBookButton: 'buying-wizard-service-book-button',
  buyingWizardVinDecisionYes: 'buying-wizard-vin-decision-yes',
  buyingWizardVinDecisionNo: 'buying-wizard-vin-decision-no',
  buyingWizardVinDecisionToggle: 'buying-wizard-vin-decision-toggle',
  buyingWizardVinFormSubmitButton: 'buying-wizard-vin-form-submit-button',
  buyingWizardBlackListButton: 'buying-wizard-blacklist-button',
  buyingWizardMobileCloseButton: 'buying-wizard-mobile-close-button',
  buyingWizardMetallicCheckbox: 'buying-wizard-metallic-checkbox',
  buyingWizardBranchButton: (id: string) => `buying-wizard-branch-${id}-button`,
  buyingWizardFeatureDropdownButton: (value: string) =>
    `buying-wizard-${value}-button`,
  buyingWizardFeatureButton: (id: string) =>
    `buying-wizard-feature-${id}-button`,
  buyingWizardColorButton: (color: string) =>
    `buying-wizard-color-${color}-button`,
  buyingWizardModelButton: (model: string) =>
    `buying-wizard-model-${model}-button`,
  buyingWizardMakeTopButton: (id: string) =>
    `buying-wizard-make-top-${id}-button`,
  buyingWizardTransmissionButton: (id: string) =>
    `buying-wizard-transmission-${id}-button`,
  buyingWizardStarRatingButton: (rating: number, prefixTestId?: string) =>
    `${prefixTestId}-star-${rating}-button`,
  buyingWizardAccidentSeverityButton: (severity: string) =>
    `buying-wizard-accident-severity-${severity}-button`,
  buyingWizardMakeSmallButton: (id: string) =>
    `buying-wizard-make-small-${id}-button`,
  buyingWizardSingleBranchContinueButton: (branchId: string) =>
    `buying-wizard-branch-${branchId}-continue-button`,

  documentListDownloadButton: 'document-list-download-button',

  datePickerClearButton: 'date-picker-clear-button',
  datePickerTimeButton: (i: number) => `date-picker-time-${i}-button`,
  datePickerTimeConfirmButton: (i: number) =>
    `date-picker-time-${i}-confirm-button`,

  signSocialFacebookButton: 'sign-social-facebook-button',
  signSocialGoogleButton: 'sign-social-google-button',
  signEmailButton: 'sign-email-button',
  signPhoneButton: 'sign-phone-button',
  signPhoneSendAgainButton: 'sign-phone-send-again-button',
  signContinue: 'sign-continue',
  signFinishButton: 'sign-finish-button',
  signResetRetryButton: 'sing-reset-retry-button',
  signResetButton: 'sing-reset-button',
  signHeaderButton: 'sign-header-button',
  signPhoneAccessLostButton: 'sign-phone-access-lost-button'
}
