import {
  addMonths,
  format,
  isBefore,
  isSameDay,
  isSameMonth,
  isToday,
  subMonths
} from 'date-fns'
import { TESTING_IDS } from 'driverama-core/constants/testingIds'

import { de } from 'date-fns/locale'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody } from 'driverama-core/components/text/Text'

import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePickerCalendar } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import {
  SBackButton,
  SCalendarWrapper,
  SFlex,
  SMonth,
  SMonthButton,
  STimeConfirmButton,
  STimeContainer,
  STimes,
  STimeSelectButton,
  SWrapper
} from './DatePicker.styled'
import {
  AvailableDate,
  DatepickerOnChangeFn,
  DatepickerValue,
  getLocale,
  isAvailable
} from './DatePicker.utils'

import IconArrowLeft from 'driverama-core/images/icons/IconArrowLeft.svg'
import IconChevronRight from 'driverama-core/images/icons/IconChevronRight.svg'

import { Button } from 'driverama-core/components/button/Button'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { size } from 'driverama-core/styles/spacing'
import { isNotNil } from 'driverama-core/utils/types'

export interface DatepickerProps {
  value: DatepickerValue
  onChange: DatepickerOnChangeFn
  branchId?: string
  availableDates?: AvailableDate[]
  minHeight?: string
  hasMonthSwitch?: boolean
  noFreeSlotsComponent?: JSX.Element
  onConfirm?: () => void
  onClear?: () => void
  onMonthChange?: (month: Date) => void
  hasTimeSelect?: boolean
  minDate?: Date
  maxDate?: Date
}

export function DatePicker(props: DatepickerProps) {
  const {
    value,
    onChange,
    availableDates,
    minHeight,
    hasMonthSwitch = true,
    onConfirm,
    onClear,
    onMonthChange,
    hasTimeSelect = true,
    maxDate,
    minDate
  } = props

  const { t } = useTranslation(['core'])

  const router = useRouter()

  const [date, setDate] = useState<Date | undefined>(value?.date)
  const [month, setMonth] = useState<Date | undefined>(
    value?.date ?? availableDates?.[0]?.date ?? new Date()
  )
  const [locale, setLocale] = useState<Locale>(de)
  const [loading, setLoading] = useState(true)

  const now = new Date()

  const onChangeMonth = useCallback(
    (month: Date) => {
      setMonth(month)
      if (onMonthChange) {
        onMonthChange(month)
      }
    },
    [setMonth, onMonthChange]
  )

  useEffect(() => {
    const fn = async () => {
      try {
        setLocale(await getLocale(router.locale))
      } finally {
        setLoading(false)
      }
    }
    fn()
  }, [router])

  const modifiers = {
    available: (date: Date) => isAvailable(date, minDate, maxDate),
    disabled: (date: Date) => !isAvailable(date, minDate, maxDate),
    outside: (date: Date) => isSameMonth(date, new Date()),
    previous: (date: Date) => isBefore(date, now) && !isToday(date)
  }

  if (loading) {
    return <SpinnerCentered />
  }

  function renderCalendarHeader() {
    if (month && !date) {
      return format(month, 'MMMM yyyy', { locale })
    }

    if (date) {
      return format(date, 'MMMM dd, yyyy', { locale })
    }

    return t('core:datepicker_select_month')
  }

  return (
    <SWrapper
      selected={!!date}
      minHeight={minHeight}
      hasTimeSelect={hasTimeSelect}
    >
      <SFlex variant="row" hasMonthSwitch={hasMonthSwitch}>
        {hasMonthSwitch && (
          <SMonthButton
            variant="outline"
            onClick={() => {
              setDate(undefined)
              onChangeMonth(subMonths(month || now, 1))
            }}
            isLeft
          >
            <IconChevronRight />
          </SMonthButton>
        )}
        <SMonth variant="setup" size="large">
          {renderCalendarHeader()}
        </SMonth>
        {hasMonthSwitch && (
          <SMonthButton
            variant="outline"
            onClick={() => {
              setDate(undefined)
              onChangeMonth(addMonths(month || now, 1))
            }}
          >
            <IconChevronRight />
          </SMonthButton>
        )}
      </SFlex>

      <Spacer size={[0, 5]} />

      <SCalendarWrapper>
        <DatePickerCalendar
          minimumDate={minDate}
          maximumDate={maxDate}
          date={date}
          locale={{
            ...locale,
            options: { ...locale.options, weekStartsOn: 1 }
          }}
          modifiers={modifiers}
          modifiersClassNames={{
            available: '-available',
            outrange: '-outrange',
            previous: '-previous'
          }}
          month={month}
          onMonthChange={m => {
            if (m) {
              onChangeMonth(m)
            }
          }}
          onDateChange={date => {
            onChange({ date: date ?? undefined, time: undefined })
            setDate(date ?? undefined)
          }}
        />

        {hasTimeSelect && date && (
          <>
            <SBackButton
              data-testid={TESTING_IDS.datePickerClearButton}
              onClick={() => setDate(undefined)}
            >
              <IconArrowLeft />
              <Spacer size={4} axis="horizontal" />
              <TextBody variant="setup">{t('core:datepicker_back')}</TextBody>
            </SBackButton>

            <STimes>
              {availableDates
                ?.find(d => date && isSameDay(d.date, date))
                ?.times.map((time, i) => {
                  return (
                    <STimeContainer key={time} selected={value?.time === time}>
                      <STimeSelectButton
                        type="button"
                        data-testid={TESTING_IDS.datePickerTimeButton(i)}
                        onClick={() => onChange({ time, date })}
                        selected={value?.time === time}
                      >
                        <TextBody key={time} variant="setup">
                          {time}
                        </TextBody>
                      </STimeSelectButton>

                      {value?.time === time && (
                        <STimeConfirmButton
                          data-testid={TESTING_IDS.datePickerTimeConfirmButton(
                            i
                          )}
                          variant="secondary"
                          onClick={onConfirm}
                        >
                          {t('core:datepicker_confirm')}
                        </STimeConfirmButton>
                      )}
                    </STimeContainer>
                  )
                })}

              <Spacer size={[0, 20]} />
            </STimes>
          </>
        )}
      </SCalendarWrapper>
      {!hasTimeSelect && (
        <Button
          variant="primary"
          css={{ width: '100%' }}
          disabled={!date}
          onClick={onConfirm}
        >
          {t('core:confirm')}
        </Button>
      )}

      {isNotNil(onClear) && (
        <Button
          variant="outline"
          css={{ width: '100%', marginTop: size(2) }}
          disabled={!date}
          onClick={onClear}
        >
          {t('core:clear')}
        </Button>
      )}
    </SWrapper>
  )
}
