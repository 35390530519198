import styled from '@emotion/styled'
import { FormControl } from 'driverama-core/components/formControl/FormControl'

export const SSeparator = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
`

export const SAdormentWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-40%);
`

export const SFormControl = styled(FormControl)`
  flex: 1;
  position: relative;
  && {
    input {
      font-size: 13px;
    }
  }
`
