import {
  InputNumber,
  Props as InputNumberProps
} from 'driverama-core/components/inputNumber/InputNumber'
import {
  InputNumberNullable,
  Props as InputNumberNullableProps
} from 'driverama-core/components/inputNumber/InputNumberNullable'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextSubhead } from 'driverama-core/components/text/Text'
import { SContainer, SInput, SRange, SSeparator } from './RangeInputs.styled'

type NullableInputs = {
  allowEmptyValues: true
  updateValue: (input: string, value: number | null) => void
}

type NonNullableInputs = {
  allowEmptyValues?: false
  updateValue: (input: string, value: number) => void
}

type Props = {
  to?: number
  from?: number
  label?: string
  adornment?: string
  fromKey: string
  toKey: string
} & (NullableInputs | NonNullableInputs)

export type RangeInputFilter = Omit<Props, 'updateValue'>

export function RangeInputs(props: Props) {
  const { to, from, label, adornment, fromKey, toKey } = props

  function renderInput(isFromInput?: boolean) {
    if (props.allowEmptyValues) {
      const nullableInputProps: Omit<
        InputNumberNullableProps,
        'onChange'
      > = isFromInput
        ? { value: from ?? null, name: fromKey, min: 0, max: to }
        : { value: to ?? null, name: toKey, min: from, max: 100000000 }

      return (
        <InputNumberNullable
          {...nullableInputProps}
          onChange={value => props.updateValue(nullableInputProps.name, value)}
          currency={adornment}
        />
      )
    }

    const inputProps: Omit<InputNumberProps, 'onChange'> = isFromInput
      ? { value: from as number, name: fromKey, min: 0, max: to }
      : { value: to as number, name: toKey, min: from, max: 100000000 }

    return (
      <InputNumber
        {...inputProps}
        onChange={value => props.updateValue(inputProps.name, value)}
        currency={adornment}
      />
    )
  }

  return (
    <SRange>
      <Spacer size={1} axis="vertical" />
      <TextSubhead>{label?.toUpperCase()}</TextSubhead>

      <Spacer size={1} axis="vertical" />

      <SContainer>
        <SInput>{renderInput(true)}</SInput>
        <SSeparator>-</SSeparator>
        <SInput>{renderInput()}</SInput>
      </SContainer>
    </SRange>
  )
}
