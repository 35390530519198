import styled from '@emotion/styled'

import { Button } from 'driverama-core/components/button/Button'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius } from 'driverama-core/styles/variables'

export const SWrapper = styled.div`
  display: none;
  background-color: ${color('night-l-700')};
  padding: ${size(4)};
  grid-gap: ${size(3)};
  grid-template-columns: 10fr auto;
  align-items: center;

  @media ${media.lte('tablet')} {
    display: grid;
  }

  @media ${media.lte('mobile')} {
    grid-template-columns: 1fr;
  }
`

export const SFilterButton = styled(Button)`
  @media ${media.lte('MD')} {
    padding: 14px ${size(4)} !important;
    border-radius: ${radius('full')} !important;
  }
`
