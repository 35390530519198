import { CSSProperties, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { OnChangeValue } from 'react-select'

export enum BoolSelectValue {
  TRUE = 'true',
  FALSE = 'false'
}

export interface SelectOption<V extends string = string> {
  value: V
  label: string
}

export type SelectOnChangeValue<
  T extends string,
  Multi extends boolean
> = OnChangeValue<SelectOption<T>, Multi>

export type SingleSelectOnChangeValue<T extends string> = SelectOnChangeValue<
  T,
  false
>

export type MultiSelectOnChangeValue<T extends string> = SelectOnChangeValue<
  T,
  true
>

export type OnChangeHandlerType<T extends string> = (
  newValue: SingleSelectOnChangeValue<T> | MultiSelectOnChangeValue<T>
) => void

export interface SelectProps<T extends string = string> {
  label?: string
  emptyLabel: string
  name: string
  options: SelectOption<T>[]
  isLoading?: boolean
  isRequired?: boolean
  onFocus?: () => void
  onBlur?: () => void
  onChange?: OnChangeHandlerType<T>
  onBeforeChange?: OnChangeHandlerType<T>
  error?: string
  disabled?: boolean
  isClearable?: boolean
  extraOptions?: { value: string; label: string }[]
  variant?: 'rounded' | 'normal'
  disabledMode?: 'solid'
  menuPlacement?: 'top' | 'bottom' | 'auto'
  hint?: ReactNode
  maxItems?: number
  className?: string
  customStyles?: {
    container: CSSProperties
  }
}

export function useBoolSelectOptions(invertedLogic?: boolean) {
  const { t } = useTranslation(['core'])

  return invertedLogic
    ? [
        {
          value: BoolSelectValue.FALSE,
          label: t('core:yes')
        },
        {
          value: BoolSelectValue.TRUE,
          label: t('core:no')
        }
      ]
    : [
        { value: BoolSelectValue.TRUE, label: t('core:yes') },
        { value: BoolSelectValue.FALSE, label: t('core:no') }
      ]
}

export function getBoolSelectDefaultValue(
  options: SelectOption<BoolSelectValue>[],
  value?: boolean
) {
  let defaultValue = null

  if (value === true) {
    defaultValue = options.find(o => o.value === BoolSelectValue.TRUE) ?? null
  }

  if (value === false) {
    defaultValue = options.find(o => o.value === BoolSelectValue.FALSE) ?? null
  }

  return defaultValue
}
