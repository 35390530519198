import { css } from '@emotion/react'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextSubhead } from 'driverama-core/components/text/Text'
import IconChevronDown from 'driverama-core/images/icons/IconChevronDown.svg'
import { size } from 'driverama-core/styles/spacing'
import { color, weight } from 'driverama-core/styles/variables'
import { useTranslation } from 'react-i18next'
import { DateInput } from './DateInput'
import { SSeparator } from './DateInputs.styled'

type Props = {
  updateValue: (value: Date | undefined, input: string) => void
  label: string
  fromKey: string
  toKey: string
  from?: Date
  to?: Date
  shortYearFormat?: boolean
}

export type DateIntervalField = Omit<Props, 'updateValue'>

export function DateInputs({
  fromKey,
  toKey,
  label,
  updateValue,
  from,
  to,
  shortYearFormat
}: Props) {
  const { t } = useTranslation('core')

  return (
    <Flex variant="column">
      <TextSubhead>{label.toUpperCase()}</TextSubhead>

      <Spacer size={1} axis="vertical" />

      <Flex
        variant="row"
        gap={1}
        css={css`
          max-width: ${size(90)};
        `}
      >
        <DateInput
          onConfirm={updateValue}
          label={t('date_from')}
          name={fromKey}
          value={from}
          adornment={<IconChevronDown color={color('night-l-100')} />}
          shortYearFormat={shortYearFormat}
          css={css`
            & label {
              font-weight: ${weight('medium')};
            }
          `}
          maxDate={to}
        />
        <SSeparator>-</SSeparator>
        <DateInput
          onConfirm={updateValue}
          label={t('date_to')}
          name={toKey}
          value={to}
          adornment={<IconChevronDown color={color('night-l-100')} />}
          shortYearFormat={shortYearFormat}
          css={css`
            & label {
              font-weight: ${weight('medium')};
            }
          `}
          minDate={from}
        />
      </Flex>
    </Flex>
  )
}
