import {
  BoolSelectValue,
  getBoolSelectDefaultValue,
  SelectOption
} from 'components/select/Select.utils'
import { MultiValue, SingleValue } from 'react-select'
import {
  getDefaultFilterMultiSelectValue,
  getDefaultSingleSelectValue
} from 'sections/filters/Filters.utils'
import { useFiltersContext } from './context/FiltersContext'
import { Action } from './context/FiltersReducer'
import { State } from './context/FiltersState'

export type SingleSelect = {
  isBoolSelect?: boolean
}

type Args<K extends keyof State, T extends string> = {
  module: K
  options: SelectOption<T>[]
  name: keyof State[K]['filters']
  label: string
  displayed?: boolean
  modifyOtherFiltersOnChange?: (
    newValue: MultiValue<SelectOption> | SingleValue<SelectOption>
  ) => Partial<State[K]['filters']> | undefined
}

export type SelectFilter<K extends keyof State> = {
  options: SelectOption[]
  name: keyof State[K]['filters']
  label: string
  displayed?: boolean
  modifyOtherFiltersOnChange?: (
    newValue: MultiValue<SelectOption> | SingleValue<SelectOption>
  ) => Partial<State[K]['filters']> | undefined
  isBoolSelect?: boolean
  isInvertedLogic?: boolean
  isMulti: boolean
  defaultValue: SelectOption | SelectOption[] | null
}

export function useMultiValueFilter<K extends keyof State, T extends string>(
  args: Args<K, T>
) {
  const { state } = useFiltersContext()

  const moduleFilters = state[args.module].filters

  const defaultValue = getDefaultFilterMultiSelectValue(
    args.options,
    (moduleFilters[args.name as keyof typeof moduleFilters] as unknown) as
      | string[]
      | null
  )

  return {
    ...args,
    defaultValue,
    isMulti: true,
    isBoolSelect: undefined
  }
}

export function useSingleValueFilter<K extends keyof State, T extends string>(
  args: Args<K, T> & SingleSelect
) {
  const { state } = useFiltersContext()

  const moduleFilters = state[args.module].filters

  const defaultValue = args.isBoolSelect
    ? getBoolSelectDefaultValue(
        args.options as SelectOption<BoolSelectValue>[],
        (moduleFilters[
          args.name as keyof typeof moduleFilters
        ] as unknown) as boolean
      )
    : getDefaultSingleSelectValue(
        args.options,
        moduleFilters[args.name as keyof typeof moduleFilters]
      )

  return {
    ...args,
    defaultValue,
    isMulti: false,
    isBoolSelect: args.isBoolSelect
  }
}

type DateIntervalArgs<K extends keyof State> = {
  module: K
  fromKey: keyof State[K]['filters']
  toKey: keyof State[K]['filters']
  label?: string
  shortYearFormat?: boolean
}

export function useDateIntervalFilter<K extends keyof State>({
  module,
  fromKey,
  toKey,
  label,
  shortYearFormat
}: DateIntervalArgs<K>) {
  const { state } = useFiltersContext()

  const moduleFilters = state[module].filters

  const from = moduleFilters[fromKey as keyof typeof moduleFilters] as
    | Date
    | undefined
  const to = moduleFilters[toKey as keyof typeof moduleFilters] as
    | Date
    | undefined

  return {
    fromKey,
    toKey,
    label,
    from,
    to,
    shortYearFormat
  }
}

type RangeInputsArgs<K extends keyof State> = {
  module: K
  fromKey: keyof State[K]['filters']
  toKey: keyof State[K]['filters']
  label?: string
  adornment?: string
}

export function useRangeInputs<K extends keyof State>({
  module,
  fromKey,
  toKey,
  label,
  adornment
}: RangeInputsArgs<K>) {
  const { state } = useFiltersContext()

  const moduleFilters = state[module].filters

  const from = moduleFilters[fromKey as keyof typeof moduleFilters]
  const to = moduleFilters[toKey as keyof typeof moduleFilters]

  return {
    fromKey,
    toKey,
    label,
    adornment,
    from: typeof from === 'number' ? from : undefined,
    to: typeof to === 'number' ? to : undefined
  }
}

type BooleanFilterType<K extends keyof State> = {
  module: K
  label: string
  name: keyof State[K]['filters']
  invertedLogic?: boolean
}

export function useBooleanFilter<K extends keyof State>({
  module,
  label,
  invertedLogic,
  name
}: BooleanFilterType<K>) {
  const { state } = useFiltersContext()

  const moduleFilters = state[module].filters

  const value = state[module].filters[name as keyof typeof moduleFilters] as
    | boolean
    | undefined

  return {
    value,
    label,
    name,
    invertedLogic
  }
}

export function setFiltersForModule<T extends keyof State>(
  module: T,
  filters: State[T]['filters']
): Action {
  return {
    type: 'SET_MODULE_FILTERS',
    payload: {
      module,
      filters
    }
  }
}

export function resetFiltersForModule(module: keyof State): Action {
  return {
    type: 'RESET_MODULE_FILTERS',
    payload: {
      module
    }
  }
}

export function setSortingForModule<T extends keyof State>(
  module: T,
  sorting: State[T]['sorting']
): Action {
  return {
    type: 'SET_MODULE_SORTING',
    payload: {
      module,
      sorting
    }
  }
}

export function resetSortingModule(module: keyof State): Action {
  return {
    type: 'RESET_MODULE_SORTING',
    payload: {
      module
    }
  }
}
