import { css } from '@emotion/react'
import { format, set } from 'date-fns'
import { useModal } from 'driverama-core/components/modal/Modal'
import { useCloseModalContext } from 'driverama-core/components/modal/Modal.utils'
import { size } from 'driverama-core/styles/spacing'
import { isNotNil } from 'driverama-core/utils/types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker } from './DatePicker'
import { AvailableDate } from './DatePicker.utils'

type Props = {
  fieldKey: string
  value?: Date
  onConfirm: (date: Date | undefined, input: string) => void
  availableDates?: AvailableDate[]
  hasTimeSelect?: boolean
  maxDate?: Date
  minDate?: Date
}

function DatePickerFilterModal({
  fieldKey,
  value,
  onConfirm,
  availableDates,
  hasTimeSelect = false,
  maxDate,
  minDate
}: Props) {
  const [date, setDate] = useState<Date>(value ?? new Date())
  const [_, setMonth] = useState<Date>(value ?? new Date())

  const closeModal = useCloseModalContext()

  const clearInput = useCallback(() => {
    onConfirm(undefined, fieldKey)
    closeModal()
  }, [onConfirm, fieldKey, closeModal])

  return (
    <DatePicker
      onMonthChange={setMonth}
      minDate={minDate}
      maxDate={maxDate}
      minHeight="auto"
      value={{
        date: date,
        time: format(date ?? new Date(), 'HH:mm')
      }}
      onConfirm={() => {
        onConfirm(date, fieldKey)
        closeModal()
      }}
      onChange={date => {
        if (date?.date && date.time) {
          const [hours, minutes] = date.time?.split(':')
          setDate(
            set(date.date, {
              minutes: parseInt(minutes),
              hours: parseInt(hours)
            })
          )
        } else if (date?.date) {
          setDate(date.date)
        }
      }}
      hasTimeSelect={hasTimeSelect}
      availableDates={availableDates}
      onClear={clearInput}
    />
  )
}

type Args = {
  onConfirm: (date: Date | undefined, input: string) => void
  availableDates?: AvailableDate[]
  hasTimeSelect?: boolean
  minDate?: Date
  maxDate?: Date
}

export function useDatePickerModal({
  onConfirm,
  availableDates,
  hasTimeSelect,
  minDate,
  maxDate
}: Args) {
  const { t } = useTranslation(['core'])
  const [date, setDate] = useState<Date>()
  const [fieldKey, setFieldKey] = useState<string>()

  const [modal, openModal] = useModal(
    () =>
      isNotNil(fieldKey) && (
        <DatePickerFilterModal
          fieldKey={fieldKey}
          value={date}
          onConfirm={onConfirm}
          availableDates={availableDates}
          hasTimeSelect={hasTimeSelect}
          minDate={minDate}
          maxDate={maxDate}
        />
      ),
    {
      closeLabel: t('core:close'),
      wrapperStyles: css`
        width: ${size(138)};
      `
    }
  )

  const handleOpenModal = useCallback(
    (value: Date | undefined, fieldKey: string) => {
      setDate(value)
      setFieldKey(fieldKey)

      openModal()
    },
    [openModal]
  )

  return {
    modal,
    openModal: handleOpenModal
  }
}
