import styled from '@emotion/styled'
import { color } from 'driverama-core/styles/variables'
import { media } from 'driverama-core/styles/media'
import { size, spaceX, spaceY } from 'driverama-core/styles/spacing'
import Logo from 'driverama-core/images/Logo.svg'
import { TextBody } from 'driverama-core/components/text/Text'
import { Button } from 'driverama-core/components/button/Button'
import { Flex } from 'driverama-core/components/Flex'

export const SFilters = styled.ul`
  display: block;
  padding: 0;

  ${spaceX(0)}
  ${spaceY(2)}
`

export const SLogo = styled(Logo)`
  color: ${color('night')};

  @media ${media.lte('tablet')} {
    width: ${size(28)};
  }
`

export const SFiltersHeader = styled(Flex)`
  margin-bottom: ${size(2)};
  height: 30px;
`

export const SFiltersHeading = styled(TextBody)`
  color: ${color('night-l-100')};
  font-size: ${size(4)};
  font-weight: bold;
`

// TODO remove "&&" after solving problem with "loading @emotion/react when it is already loaded."
// it is probably caused by importing anything from driverama-core
export const SClearButton = styled(Button)`
  && {
    line-height: ${size(4)};
    padding: ${size(1)} ${size(4)};
    font-size: ${size(3)};
  }
`
