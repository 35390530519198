import { SContainer } from './LeftMenu.styled'

import { RangeInputFilter } from 'components/rangeInputs/RangeInputs'
import { State } from 'sections/filters/context/FiltersState'
import { SelectFilter } from 'sections/filters/FilterHooks'
import { Filters } from 'sections/filters/Filters'

type Props<K extends keyof State> = {
  module: K
  menuFilters?: {
    dropdowns?: SelectFilter<K>[]
    rangeInputs?: RangeInputFilter[]
  }
}

export function LeftMenu<K extends keyof State>({
  module,
  menuFilters
}: Props<K>) {
  return (
    <SContainer>
      <Filters module={module} menuFilters={menuFilters} />
    </SContainer>
  )
}
