import styled from '@emotion/styled'

import { color } from 'driverama-core/styles/variables'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { invisibleScrollbars } from 'driverama-core/styles/common'

export const SContainer = styled.nav`
  width: ${size(65)};
  min-width: ${size(65)};
  height: calc(100vh - var(--header-height));

  padding: ${size(4)};
  border-right: 2px solid ${color('night-l-700')};

  overflow-y: auto;
  ${invisibleScrollbars}

  @media ${media.lte('tablet')} {
    display: none;
  }
`
