import { useDatePickerModal } from 'components/datePicker/DatePickerModal'
import { format } from 'date-fns'
import { ReactNode } from 'react'
import { SAdormentWrapper, SFormControl } from './DateInputs.styled'

type Props = {
  value?: Date
  name: string
  label?: string
  adornment?: ReactNode
  shortYearFormat?: boolean
  className?: string
  onConfirm: (date: Date | undefined, input: string) => void
  maxDate?: Date
  minDate?: Date
}

export function DateInput({
  label,
  value,
  name,
  adornment,
  shortYearFormat,
  className,
  onConfirm,
  minDate,
  maxDate
}: Props) {
  const { modal, openModal } = useDatePickerModal({
    onConfirm,
    minDate,
    maxDate
  })

  return (
    <>
      <button onClick={() => openModal(value, name)}>
        <SFormControl label={label} className={className}>
          <input
            type="text"
            value={
              value
                ? format(value, shortYearFormat ? 'dd.MM.yy' : 'dd.MM.yyyy')
                : ''
            }
          />
          {adornment && <SAdormentWrapper>{adornment}</SAdormentWrapper>}
        </SFormControl>
      </button>
      {modal}
    </>
  )
}
