import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { TextSubhead } from 'driverama-core/components/text/Text'

export const SInput = styled.div`
  max-width: ${size(35)};
`

export const SSeparator = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${size(1)};
  margin-right: ${size(1)};
`

export const SRange = styled.div`
  display: grid;
`

export const SContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${size(2)};
`

export const STextSubhead = styled(TextSubhead)`
  margin-top: ${size(12)};

  @media ${media.lte('mobile')} {
    margin-top: ${size(8)};
    text-align: center;
  }
`
