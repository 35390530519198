import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Button,
  ButtonCircle,
  ButtonTransparent
} from 'driverama-core/components/button/Button'
import { Flex } from 'driverama-core/components/Flex'
import { TextBody } from 'driverama-core/components/text/Text'
import { fadeIn } from 'driverama-core/styles/animations'
import { invisibleScrollbars } from 'driverama-core/styles/common'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, time, weight } from 'driverama-core/styles/variables'

export const SWrapper = styled.div<{
  selected?: boolean
  minHeight?: string
  hasTimeSelect: boolean
}>`
  position: relative;
  margin: 0 auto;

  max-width: ${size(150)};
  overflow-y: hidden;

  /* TODO: this is basically hack, imho STimes should not be styled with absolute positioning  */
  ${({ selected, minHeight, hasTimeSelect }) =>
    selected &&
    hasTimeSelect &&
    css`
      min-height: ${minHeight ?? size(220)};

      &:after {
        content: '';
        position: absolute;
        bottom: ${size(-1)};
        height: ${size(26)};

        pointer-events: none;

        @media ${media.gt('mobile')} {
          width: 36%;
          right: 0;

          background: linear-gradient(
            to top,
            ${color('white')},
            ${color('white', 0)}
          );
        }
      }
    `}

  .nice-dates-grid,
  .nice-dates-week-header {
    opacity: 1;

    transition-property: margin-left, width, opacity;
    transition-duration: ${time('control')};
    transition-timing-function: ease-in-out;
  }

  .nice-dates-grid {
    width: 100%;
    margin: 0;
  }

  .nice-dates-week-header {
    width: 100%;
    margin: 0;
    padding: ${size(8)} 0 ${size(3)} 0;

    box-shadow: none;

    &_day {
      color: ${color('night-l-100')};
      font-size: 11px;
      font-weight: ${weight('bold')};
      text-transform: uppercase;
    }

    @media ${media.lte('mobile')} {
      padding: 0;
      padding-top: ${size(8)};
      padding-bottom: ${({ selected }) => (selected ? size(5) : 0)};
    }
  }

  .nice-dates-navigation {
    display: none;
  }

  .nice-dates-day {
    min-width: ${size(10)};
    min-height: ${size(10)};

    &_date {
      width: ${size(10)};
      height: ${size(10)};

      display: grid;
      place-items: center;

      padding: ${size(2)};
      border: none;
      border-radius: ${radius('full')};

      color: ${color('night-l-100')};
      font-size: 14px;
      font-weight: ${weight('bold')};

      transition: all ${time('control')} ease-in-out;
    }

    &_month {
      display: none;
    }

    &::before,
    &::after {
      background-color: transparent !important;
      border: none !important;
    }
  }

  .-today {
    .nice-dates-day_date {
      background-color: ${color('sun-l-500')};
      color: ${color('sun-d-400')};
      border-color: transparent;
    }
  }

  .-available {
    .nice-dates-day_date {
      border: 2px solid ${color('night-l-650')};
    }
  }

  .-previous {
    .nice-dates-day_date {
      color: ${color('night-text')};
    }
  }

  .-outside {
    .nice-dates-day_date {
      opacity: 0;
    }
  }

  .-disabled {
    .nice-dates-day_date {
      color: ${color('night-text')};
    }
  }

  .-selected {
    .nice-dates-day_date {
      color: ${color('white')};
      background-color: ${color('night-l-100')};
      border: 2px solid ${color('night-l-100')};
    }
  }

  @media (hover: hover) {
    .nice-dates-day_date:hover {
      border: 2px solid ${color('night-l-100')};
    }
  }

  @media ${media.gt('mobile')} {
    min-height: ${({ minHeight }) => minHeight ?? size(145)};

    .nice-dates-grid,
    .nice-dates-week-header {
      margin-left: 72px;

      ${({ selected, hasTimeSelect = true }) => css`
        width: ${selected && hasTimeSelect
          ? 'calc(65% - 16px)'
          : 'calc(100% - 144px)'};
        ${selected && hasTimeSelect && 'margin: 0;'}
      `}
    }
  }
`

export const SFlex = styled(Flex)<{ hasMonthSwitch: boolean }>`
  justify-content: ${({ hasMonthSwitch }) =>
    hasMonthSwitch ? 'space-between' : 'center'};

  @media ${media.gt('mobile')} {
    justify-content: ${({ hasMonthSwitch }) =>
      hasMonthSwitch ? 'space-between' : 'flex-start'};
  }
`

export const SCalendarWrapper = styled.div`
  position: relative;

  @media ${media.gt('mobile')} {
    margin-left: -5px;
  }
`

export const SMonth = styled(TextBody)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${color('night-d-200')};
`

export const SMonthButton = styled(ButtonCircle)<{ isLeft?: boolean }>`
  ${({ isLeft }) => isLeft && 'transform: rotate(180deg)'};

  padding: ${size(2)};

  @media ${media.gt('mobile')} {
    padding: ${size(3)};
  }
`

export const SBackButton = styled(ButtonTransparent)`
  position: absolute;
  top: ${size(8)};
  left: 0;

  ${TextBody}, svg {
    color: ${color('night-l-100')};
  }

  @media ${media.gt('mobile')} {
    display: none;
  }
`

export const STimes = styled.div`
  --top-offset: ${size(22)};

  position: absolute;
  top: var(--top-offset);
  right: 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${size(2)};

  width: 100%;
  overflow-y: auto;
  height: calc(95% - var(--top-offset));

  animation: ${fadeIn} ${time('control')} ease-in-out;

  ${invisibleScrollbars};

  @media ${media.gt('mobile')} {
    top: ${size(7)};
    height: calc(100% - ${size(12)});
    max-height: ${size(125)};
    grid-template-columns: 1fr;
    width: 35%;
  }
`

export const STimeContainer = styled.div<{ selected?: boolean }>`
  display: grid;
  grid-template-columns: ${({ selected }) =>
    selected ? 'minmax(auto, 1fr) 1fr' : '1fr'};
  grid-column-gap: ${size(2)};
`

export const STimeConfirmButton = styled(Button)`
  display: flex;
  align-items: center;

  padding: ${size(3)};

  animation: ${fadeIn} ${time('control')} ease-in-out;
`

export const STimeSelectButton = styled(ButtonTransparent)<{
  selected?: boolean
}>`
  width: 100%;
  padding: ${size(3)} 0;

  border: 2px solid ${color('night-l-650')};
  border-radius: ${radius('corner')};

  transition: all ${time('control')} ease-in-out;

  ${({ selected }) => css`
    background-color: ${selected ? color('night-l-100') : color('white')};
    border-color: ${selected ? color('night-l-100') : color('night-l-650')};

    ${TextBody} {
      color: ${selected ? color('white') : color('night-l-100')};
    }
  `};

  @media (hover: hover) {
    &:hover {
      border-color: ${color('night-l-100')};
    }
  }
`
