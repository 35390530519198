import { useTranslation } from 'react-i18next'

import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextHeader } from 'driverama-core/components/text/Text'

import { Filters } from 'sections/filters/Filters'

import { RangeInputFilter } from 'components/rangeInputs/RangeInputs'
import { State } from 'sections/filters/context/FiltersState'
import { SelectFilter } from 'sections/filters/FilterHooks'
import { SWrapper } from './FiltersModal.styled'

type Props<K extends keyof State> = {
  module: K
  menuFilters?: {
    dropdowns?: SelectFilter<K>[]
    rangeInputs?: RangeInputFilter[]
  }
}

export function FiltersModal<K extends keyof State>({
  module,
  menuFilters
}: Props<K>) {
  const { t } = useTranslation(['core'])

  return (
    <SWrapper>
      <TextHeader variant="h4">{t('core:table_filters')}</TextHeader>

      <Spacer size={4} />

      <Filters withoutHeading module={module} menuFilters={menuFilters} />
    </SWrapper>
  )
}
