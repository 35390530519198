import { useTranslation } from 'react-i18next'
import { HeaderProps } from 'react-table'

import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBodyBold } from 'driverama-core/components/text/Text'
import { Tooltip } from 'driverama-core/components/tooltip/Tooltip'
import { color } from 'driverama-core/styles/variables'
import { useCopyToClipboard } from 'driverama-core/utils/hooks'

import IconCopy from 'images/icons/IconCopy.svg'

function isRowWithVIN(vin: string | unknown): vin is string {
  return vin !== undefined
}

export function VinHeaderCell<T extends Record<string, unknown>>(
  props: HeaderProps<T>
) {
  const { t } = useTranslation(['core'])

  const data: T[] = props.data

  const selector = props.column.id

  const { copyToClipboard } = useCopyToClipboard()

  const copyAllFilteredVINs = async () => {
    const vins = data
      .map(row =>
        typeof row[selector] === 'string' ? row[selector] : undefined
      )
      .filter(isRowWithVIN)
      .join(', ')

    copyToClipboard(vins, t('core:table_column_vin_copy_to_clipboard_success'))
  }

  return (
    <Flex variant="row">
      <TextBodyBold variant="caption" css={{ marginTop: 2 }}>
        {t('core:table_column_vin')}
      </TextBodyBold>

      {data.length > 0 && (
        <>
          <Spacer size={2} />

          <Tooltip
            content={t('core:table_column_vin_copy_to_clipboard')}
            placement="top"
          >
            <ButtonTransparent onClick={copyAllFilteredVINs}>
              <IconCopy width={18} height={18} color={color('night-l-200')} />
            </ButtonTransparent>
          </Tooltip>
        </>
      )}
    </Flex>
  )
}
