import { omitNilValues } from 'driverama-core/utils/object'
import { SortType } from 'sections/filters/Filters.utils'

// filters out sort 'none' sortings
function isSorting(sort: SortType): sort is 'asc' | 'desc' {
  return sort === 'asc' || sort === 'desc'
}

export function createSortArray(sort: Partial<Record<string, SortType>>) {
  const sortArray = Object.entries(omitNilValues(sort))
    .filter(([_, value]) => isSorting(value))
    .map(([key, value]) => [key, value].join(','))

  return !!sortArray.length ? sortArray : undefined
}
