import { useTranslation } from 'react-i18next'
import { MultiValue, SingleValue } from 'react-select'

import { Select } from 'components/select/Select'
import { SelectOption } from 'components/select/Select.utils'

import { css } from '@emotion/react'
import { BoolFilter, BoolSwitch } from 'components/boolSwitch/BoolSwitch'
import { DateInputs, DateIntervalField } from 'components/dateInputs/DateInputs'
import {
  RangeInputFilter,
  RangeInputs
} from 'components/rangeInputs/RangeInputs'
import { weight } from 'driverama-core/styles/variables'
import equal from 'fast-deep-equal'
import { useFiltersContext } from './context/FiltersContext'
import { initialState, State } from './context/FiltersState'
import { SelectFilter, setFiltersForModule } from './FilterHooks'
import {
  SClearButton,
  SFilters,
  SFiltersHeader,
  SFiltersHeading
} from './Filters.styled'
import { FilterModule } from './Filters.utils'

type Props<K extends keyof State> = {
  withoutHeading?: boolean
  module: K
  menuFilters?: {
    dropdowns?: SelectFilter<K>[]
    rangeInputs?: RangeInputFilter[]
    boolFilters?: BoolFilter<K>[]
    intervalFields?: DateIntervalField[]
  }
}

export function Filters<K extends keyof State>({
  withoutHeading = false,
  module,
  menuFilters
}: Props<K>) {
  const { t } = useTranslation(['core'])

  const { state, dispatch } = useFiltersContext()

  const moduleFilters = state[module].filters
  const defaultFilters = initialState[module].filters

  const handleClearFilters = () => {
    dispatch({ type: 'RESET_MODULE_FILTERS', payload: { module } })
  }

  const updateRangeInputValue = (input: string, value: number | null) => {
    dispatch(setFiltersForModule(module, { [input]: value }))
  }

  const handleChangeBoolFilterValue = (
    filterName: keyof State[K]['filters'],
    newValue?: boolean
  ) => {
    const currValue = moduleFilters[
      filterName as keyof typeof moduleFilters
    ] as boolean | undefined

    if (currValue === newValue) {
      dispatch(setFiltersForModule(module, { [filterName]: undefined }))

      return
    }

    dispatch(setFiltersForModule(module, { [filterName]: newValue }))
  }

  const updateDateInterval = (value: Date | undefined, input: string) => {
    dispatch(setFiltersForModule(module, { [input]: value }))
  }

  const handleMultiSelectChange = (
    selected: MultiValue<SelectOption>,
    filterName: string,
    otherModifiedFilters?: Partial<State[FilterModule]['filters']>
  ) => {
    if (typeof selected !== 'string') {
      const ids = selected.map(item => item.value)
      dispatch(
        setFiltersForModule(module, {
          ...(otherModifiedFilters ?? {}),
          [filterName]: ids
        })
      )
    }
  }

  const handleSingleSelectChange = (
    selected: SingleValue<SelectOption>,
    filterName: string,
    isBoolSelect?: boolean
  ) => {
    if (selected?.value === undefined) {
      dispatch(setFiltersForModule(module, { [filterName]: undefined }))
    } else {
      dispatch(
        setFiltersForModule(module, {
          [filterName]: isBoolSelect
            ? selected.value === 'true'
              ? true
              : false
            : selected?.value
        })
      )
    }
  }

  const validFilterValues = Object.entries(moduleFilters).some(
    ([key, value]) => {
      // in case that filter is defaultFilter
      if (
        equal(
          defaultFilters?.[key as keyof State[FilterModule]['filters']],
          value
        )
      ) {
        return false
      }

      return Array.isArray(value) ? value.length > 0 : value !== undefined
    }
  )

  const isFiltering = !equal(moduleFilters, defaultFilters) && validFilterValues

  return (
    <>
      <SFiltersHeader justify="between" variant="row" align="center">
        {!withoutHeading && (
          <SFiltersHeading>{t('core:table_filters')}</SFiltersHeading>
        )}

        {isFiltering && (
          <SClearButton variant="tertiary" onClick={handleClearFilters}>
            {t('core:table_clear_filters')}
          </SClearButton>
        )}
      </SFiltersHeader>

      <SFilters>
        {menuFilters?.dropdowns?.map(filter => {
          const displayed = filter.displayed ?? true
          const filterName = filter.name as string

          if (displayed) {
            return (
              <Select
                isMulti={filter.isMulti}
                key={filterName}
                name={filterName}
                options={filter.options}
                label={filter.label}
                emptyLabel={t('core:choose_some')}
                css={css`
                  & label {
                    font-weight: ${weight('medium')};
                  }
                `}
                onChange={value => {
                  const modifiedFilters = filter.modifyOtherFiltersOnChange?.(
                    value
                  )

                  if (!filter.isMulti) {
                    handleSingleSelectChange(
                      value as SingleValue<SelectOption>,
                      filterName,
                      filter.isBoolSelect
                    )
                  } else {
                    handleMultiSelectChange(
                      value as MultiValue<SelectOption>,
                      filterName,
                      modifiedFilters
                    )
                  }
                }}
                value={filter.defaultValue}
                isClearable={filter.isMulti === false}
              />
            )
          }

          return null
        })}

        {menuFilters?.boolFilters?.map(boolFilter => (
          <BoolSwitch
            {...boolFilter}
            onChange={handleChangeBoolFilterValue}
            key={boolFilter.label}
          />
        ))}

        {menuFilters?.intervalFields?.map(intervalField => (
          <DateInputs
            {...intervalField}
            key={intervalField.fromKey}
            updateValue={updateDateInterval}
          />
        ))}

        {menuFilters?.rangeInputs?.map(rangeInput => (
          <RangeInputs
            {...rangeInput}
            key={rangeInput.fromKey}
            updateValue={updateRangeInputValue}
            allowEmptyValues
          />
        ))}
      </SFilters>
    </>
  )
}
